import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, InputGroup, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import {getStaff} from '../Staff/StaffFunctions.js';
import AddAccount from './AddAccount';
import {getBanners, getDeliveryDate} from "./AccountFunctions";
import {getProductsParent} from '../Products/ProductFunctions.js';
import {getCategories} from '../Categories/CategoryFunctions.js';
import {getOwners} from "../Owners/OwnerFunctions";
import {getFactors} from '../Common/Factors.js';


moment.tz.setDefault("America/Toronto");

var money = require("money-math");

class AccountList extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);
        this.goToAccount = this.goToAccount.bind(this);

        this.state = {
            accounts: [],
            loading: true,
            since_last_order: 0,
            num_orders: 0,
            total_spent: 0,
            staff: [],

            startDate: (localStorage.getItem('acc_startDate') != null ? moment(localStorage.getItem('acc_startDate')) : moment().startOf('year')),
            endDate: (localStorage.getItem('acc_endDate') != null ? moment(localStorage.getItem('acc_endDate')) : moment().endOf('day')),
            account_realfake: (localStorage.getItem('account_realfake') != null ? localStorage.getItem('account_realfake') : 1),
            account_status: (localStorage.getItem('account_status') != null ? localStorage.getItem('account_status') : 1),
            account_type: (localStorage.getItem('account_type') != null ? localStorage.getItem('account_type') : 99),
            account_status_name: (localStorage.getItem('account_status_name') != null ? localStorage.getItem('account_status_name') : "Active"),
            account_realfake_name: (localStorage.getItem('account_realfake_name') != null ? localStorage.getItem('account_realfake_name') : "Real"),
            account_type_name: (localStorage.getItem('account_type_name') != null ? localStorage.getItem('account_type_name') : "On-Premise & Retail"),
            account_rep: (localStorage.getItem('account_rep') != null ? localStorage.getItem('account_rep') : localStorage.getItem('staff_id')),
            account_layout: (localStorage.getItem('account_layout') != null ? localStorage.getItem('account_layout') : null),
            account_rep_name: (localStorage.getItem('account_rep_name') != null ? localStorage.getItem('account_rep_name') : localStorage.getItem('first_name') + " " + localStorage.getItem('last_name')),
            banner: (localStorage.getItem('banner') != null ? localStorage.getItem('banner') : null),
            product_parent_name: (localStorage.getItem('product_parent_name') != null ? localStorage.getItem('product_parent_name') : "All"),
            product_parent_id: (localStorage.getItem('product_parent_id') != null ? localStorage.getItem('product_parent_id') : null),
            category_name: (localStorage.getItem('category_name') != null ? localStorage.getItem('category_name') : "All"),
            category_id: (localStorage.getItem('category_id') != null ? localStorage.getItem('category_id') : null),
            factor_name: (localStorage.getItem('factor_name') != null ? localStorage.getItem('factor_name') : "All"),
            factor_id: (localStorage.getItem('factor_id') != null ? localStorage.getItem('factor_id') : null),
            categories: [],
            products: {},
            banners: [],
            factors: [],
            products_sort: [],
            account_types: {
                0: "Special",
                1: "On-Premise - Cat A",
                2: "TBS",
                3: "LCBO",
                4: "Individual",
                5: "Grocery",
                6: "Agency",
                7: "LCBO Warehouse",
                8: "NSLC",
                10: "Duty-Free",
                11: "Convenience",
                98: "All Retail",
                99: "All OP & Retail"
            },
            account_statuses: {0: "Closed", 1: "Active", 2: "Lead"},
            account_layout_name: (localStorage.getItem('account_layout_name') != null ? localStorage.getItem('account_layout_name') : "All"),
            company: (localStorage.getItem('company') != null ? localStorage.getItem('company') : null),
            companies_name: (localStorage.getItem('company_name') != null ? localStorage.getItem('company_name') : "All"),
            companies: [],
            layouts: {"EXP": "EXP", "PSS": "PSS", "CONV": "CONV", "DC": "DC", "FRS": "FRS", "BOUTIQUE": "BOUTIQUE", "PROT EXP": "PROT EXP", "CONV/DC": "CONV/DC"},
            realfake_types: {1: "Real", 0: "Fake"},
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jun 2018"), moment().endOf('day')]
            },
            sort: (localStorage.getItem('sort') != null ? localStorage.getItem('sort') : "name"),
            sort_order: (localStorage.getItem('sort_order') != null ? localStorage.getItem('sort_order') * -1 : -1),
            loadanyway: false
        };
    }

    componentDidMount() {
        document.title = "Account List | Bevvy";
        var self = this;
        getStaff(function (staff) {
            staff[0] = {
                'first_name': 'All',
                'last_name': ''
            };
            staff['null'] = {
                'first_name': '(Unassigned)',
                'last_name': ''
            };
            getOwners(function (owners) {
                getProductsParent(function (products) {
                    getCategories(function (categories) {
                        getBanners(function (banners) {
                            getFactors(function (factors) {
                                var products_sort = []
                                Object.keys(products).map(function (product_parent_id, key) {
                                    products[product_parent_id].visibile = false;
                                    products_sort.push({id: product_parent_id, name: products[product_parent_id].name})
                                });
                                products_sort = products_sort.sort((a, b) => {
                                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                        return -1;
                                    }
                                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                        return 1;
                                    }
                                    return 0;
                                });
                                self.setState({
                                    staff: staff,
                                    banners: banners,
                                    companies: owners,
                                    products: products,
                                    categories: categories,
                                    products_sort: products_sort,
                                    factors: factors
                                }, () => {
                                    self.getAccounts()
                                });
                            });
                        });
                    });
                });
            });
        });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            localStorage.setItem('acc_startDate', picker.startDate);
            localStorage.setItem('acc_endDate', picker.endDate);
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, () => {
                this.getAccounts()
            });
        }
    }

    changeCompany = (company) => {
        if (company == null) {
            var companies_name = "All"
        } else {
            var companies_name = this.state.companies[company].name
        }
        localStorage.setItem('company', company);
        localStorage.setItem('company_name', companies_name);
        this.setState({
            company: company,
            companies_name: companies_name
        }, () => {
            this.getAccounts()
        });
    }

    changeAccountType = (account_type) => {
        if (account_type == null) {
            var account_type_name = "All"
        } else {
            var account_type_name = this.state.account_types[account_type]
        }
        localStorage.setItem('account_type', account_type);
        localStorage.setItem('account_type_name', account_type_name);
        this.setState({
            account_type: account_type,
            account_type_name: account_type_name
        }, () => {
            this.getAccounts()
        });
    };

    changeBanner = (banner) => {
        if (banner == "All") {
            banner = null;
        }
        localStorage.setItem('banner', banner);
        this.setState({
            banner: banner,
        }, () => {
            this.getAccounts()
        });
    };

    changeProduct = (product_parent_id) => {
        if (product_parent_id == null) {
            var product_parent_name = "All"
        } else {
            var product_parent_name = this.state.products[product_parent_id].name
        }
        localStorage.setItem('product_parent_name', product_parent_name);
        localStorage.setItem('product_parent_id', product_parent_id);
        this.setState({
            product_parent_id: product_parent_id,
            product_parent_name: product_parent_name
        }, () => {
            this.getAccounts();
        });
    };

    changeCategory = (category_id) => {
        if (category_id == null) {
            var category_name = "All"
        } else {
            var category_name = this.state.categories[category_id].name
        }
        localStorage.setItem('category_name', category_name);
        localStorage.setItem('category_id', category_id);
        this.setState({
            category_id: category_id,
            category_name: category_name
        }, () => {
            this.getAccounts();
        });
    };

    changeAccountStatus = (account_status) => {
        if (account_status == null) {
            var account_status_name = "All"
        } else {
            var account_status_name = this.state.account_statuses[account_status]
        }
        localStorage.setItem('account_status', account_status);
        localStorage.setItem('account_status_name', account_status_name);
        this.setState({
            account_status: account_status,
            account_status_name: account_status_name
        }, () => {
            this.getAccounts()
        });
    };

    changeAccountRealFake = (account_realfake) => {
        var account_realfake_name = this.state.realfake_types[account_realfake];
        localStorage.setItem('account_realfake', account_realfake);
        localStorage.setItem('account_realfake_name', account_realfake_name);
        this.setState({
            account_realfake: account_realfake,
            account_realfake_name: account_realfake_name
        }, () => {
            this.getAccounts()
        });
    };

    changeFactor = (factor_id) => {
        if (factor_id == null) {
            var factor_name = "All"
        } else {
            var factor_name = this.state.factors[factor_id].name
        }
        localStorage.setItem('factor_name', factor_name);
        localStorage.setItem('factor_id', factor_id);
        this.setState({
            factor_id: factor_id,
            factor_name: factor_name
        }, () => {
            this.getAccounts();
        });
    };

    changeRep = (sales_rep_id) => {
        var account_rep_name = this.state.staff[sales_rep_id].first_name + " " + this.state.staff[sales_rep_id].last_name;
        localStorage.setItem('account_rep', sales_rep_id);
        localStorage.setItem('account_rep_name', account_rep_name);
        this.setState({
            account_rep: sales_rep_id,
            account_rep_name: account_rep_name
        }, () => {
            this.getAccounts()
        });
    };

    changeLayout = (account_layout) => {
        var account_layout_name = "All"
        if (account_layout != null) {
            account_layout_name = account_layout;
        }
        localStorage.setItem('account_layout', account_layout);
        localStorage.setItem('account_layout_name', account_layout_name);
        this.setState({
            account_layout: account_layout,
            account_layout_name: account_layout_name
        }, () => {
            this.getAccounts()
        });
    };

    goToAccount = (account_id) => {
        this.props.history.push("/account/" + account_id);
    };

    getAccounts() {
        var self = this;
        if (
            this.state.loadanyway == false &&
            (String(this.state.account_status) == "null" || this.state.account_status == 0) &&
            String(this.state.account_rep) == "0" &&
            String(this.state.account_realfake) == "1" &&
            (String(this.state.account_type) == "null" || String(this.state.account_type) == "1" || String(this.state.account_type) == "98" || String(this.state.account_type) == "99") &&
            String(this.state.banner) == "null"
        ) {
            Swal({
                title: "Are you sure?",
                text: "This will load a lot of accounts. You might want to adjust your filters.",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Load Anyway",
                cancelButtonText: "Cancel",
            }).then((result) => {
                if (result.value) {
                    self.setState({
                        loadanyway: true,
                    });
                    self.getAccounts();
                } else {
                    self.setState({
                        loading: false
                    });
                }
            })
        } else {
            this.setState({
                loading: true,
                sort_order: self.state.sort_order * -1
            });
            axios.defaults.withCredentials = true;
            axios.get(API_ROOT + '/accounts/summary/?account_realfake=' + self.state.account_realfake + '&account_type=' + self.state.account_type + '&account_status=' + self.state.account_status + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&sales_rep_id=" + self.state.account_rep + "&account_layout=" + self.state.account_layout + "&company_id=" + self.state.company + "&banner=" + self.state.banner + "&product_parent_id=" + self.state.product_parent_id + "&category_id=" + self.state.category_id + "&factor_id=" + self.state.factor_id)
                .then(function (response) {
                    var num_accounts = 0;
                    response.data.map(function (account, key) {
                        num_accounts++;
                    });
                    self.setState({
                        accounts: response.data,
                        loading: false,
                        loadanyway: false,
                    });
                    self.onSort(null, self.state.sort)
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            accounts: [],
                            loading: false
                        });
                    }
                });
        }
    }

    onSort(event, sortKey) {
        localStorage.setItem('sort', sortKey);
        localStorage.setItem('sort_order', this.state.sort_order);
        var sort_order = this.state.sort_order * -1;
        if (sortKey != this.state.sort) {
            sort_order = 1;
        }

        function format_search_val(val) {
            if (typeof val == "string" && val.indexOf('LCBO ') > -1) {
                val = val.replace('LCBO ', '');
            } else if (typeof val == "string" && val.indexOf('LCBO') > -1) {
                val = val.replace('LCBO', '100000000000');
            }
            if (val == "") {
                val = 0;
            }
            if (isNaN(val)) {
                return val.toLowerCase();
            } else {
                return parseFloat(val);
            }
        }

        var accounts = this.state.accounts.sort((a, b) => {
            if (typeof format_search_val(a[sortKey]) == "string" && typeof format_search_val(b[sortKey]) != "string") {
                return (sort_order == -1 ? -1 : 1);
            } else {
                if (format_search_val(a[sortKey]) > format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? -1 : 1);
                }
                if (format_search_val(a[sortKey]) < format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? 1 : -1);
                }
            }
            return 0;
        });
        this.setState({
            sort: sortKey,
            sort_order: sort_order,
            accounts: accounts
        });
    }

    export = () => {
        var currentdate = new Date();
        var csv = "Filters:\n"
        csv += ',Period:,,' + this.state.startDate.format('MMM. D/YY') + ' - ' + this.state.endDate.format('MMM. D/YY') + '\n';
        csv += ',Account Type:,,' + this.state.account_type_name + '\n';
        csv += ',Banner:,,' + (String(this.state.banner) == "null" ? "All" : this.state.banner) + '\n';
        csv += ',Status:,,' + this.state.account_status_name + '\n';
        csv += ',Rep:,,' + this.state.account_rep_name + '\n';
        csv += ',Layout:,,' + this.state.account_layout_name + '\n';
        csv += ',Company:,,' + this.state.companies_name + '\n';
        csv += ',Product:,,' + this.state.product_parent_name + '\n';
        csv += ',Factor:,,' + this.state.factor_name + '\n';
        csv += ',Category:,,' + this.state.category_name + '\n';
        csv += '\n';
        csv += 'Generated:,' + currentdate + '\n';
        csv += '\n';
        csv += 'Account #, License #, Name, Address, City, Sales Rep, Days Since Last Order, # Orders, Total $, Total Owing $, HL, Last Visit, Delivery Day\n';
        for (var i = 0; i < this.state.accounts.length; i++) {
            var account = this.state.accounts[i];
            var rep = "";
            if (typeof this.state.staff[account.sales_rep_id] != "undefined") {
                rep = this.state.staff[account.sales_rep_id].first_name + " " + this.state.staff[account.sales_rep_id].last_name;
            }
            if (rep == "(Unassigned) ") {
                rep = "";
            }
            csv += '"' + account.account_id + '","' + account.license_num + '","' + account.name + '","' + account.address1 + '","' + account.city + '","' + rep + '","' + account.since_last_order + '","' + account.num_orders + '","$' + account.total_spent.toLocaleString("en-US") + '","$' + account.owing.toLocaleString("en-US") + '","' + account.hl.toFixed(2) + '","' + getDeliveryDate(account.d_day, account.d_week, "display") + '"\n';
        }
        const blob = new Blob([csv], {type: "text/csv"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "Account List.csv";
        link.href = url;
        link.click();
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        var num_orders = 0;
        var total_spent = 0;
        var owing = 0;
        var hl = 0;

        return (
            <ContentWrapper>
                <div className="content-heading mb-1">
                    <div>Account List</div>
                    <div className="ml-auto">
                        <AddAccount goToAccount={this.goToAccount}/>
                    </div>
                </div>
                <div className="mb-1 row" style={{zIndex: 1000}}>
                    <div className="col col-6 mb-2">
                        <div className="text-muted font-weight-bold">ACCOUNT FILTERS</div>
                        <div className="form-inline">
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color={(String(this.state.account_type) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                        Type: <b>{this.state.account_type_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeAccountType(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.account_types).map((key, i) =>
                                            <DropdownItem key={key} onClick={() => this.changeAccountType(key)}>{this.state.account_types[key]}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color={(String(this.state.banner) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                        Banner: <b>{(String(this.state.banner) == "null" ? "All" : this.state.banner)}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeBanner(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {this.state.banners.map((banner, i) =>
                                            <DropdownItem key={banner} onClick={() => this.changeBanner(banner)}>{banner}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color={(String(this.state.account_status) == "1" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                        Status: <b>{this.state.account_status_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeAccountStatus(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.account_statuses).map((key, i) =>
                                            <DropdownItem key={key} onClick={() => this.changeAccountStatus(key)}>{this.state.account_statuses[key]}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color={(String(this.state.account_realfake) == "1" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                        <b>{this.state.account_realfake_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {Object.keys(this.state.realfake_types).map((key, i) =>
                                            <DropdownItem key={key} onClick={() => this.changeAccountRealFake(key)}>{this.state.realfake_types[key]}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup direction="down">
                                <UncontrolledButtonDropdown direction="down">
                                    <DropdownToggle direction="down" color={(String(this.state.account_rep) == "0" ? "primary" : "warning")} caret
                                                    style={{fontSize: '13px', width: 'auto'}}>
                                        Rep: <b>{this.state.account_rep_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeRep(0)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.staff).map((sales_rep_id, i) => {
                                            if (this.state.staff[sales_rep_id].status == 1) {
                                                return (<DropdownItem key={sales_rep_id}
                                                                      onClick={() => this.changeRep(sales_rep_id)}>{this.state.staff[sales_rep_id].first_name} {this.state.staff[sales_rep_id].last_name}</DropdownItem>)
                                            }
                                        }, this)}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color={(String(this.state.account_layout) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                        <b>Layout: {this.state.account_layout_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeLayout(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.layouts).map((key, i) =>
                                            <DropdownItem key={key} onClick={() => this.changeLayout(key)}>{this.state.layouts[key]}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                        </div>
                    </div>
                    <div className="col col-6 mb-2">
                        <div className="text-muted font-weight-bold">ORDER SUMMARY FILTERS</div>
                        <div className="form-inline">
                            <DateRangePicker
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                maxDate={moment()}
                                ranges={this.state.ranges}
                                onEvent={this.updateDates}
                                autoUpdateInput={true}
                            >
                                <Button className="selected-date-range-btn" color="primary">
                                    <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                                </Button>
                            </DateRangePicker>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color={(String(this.state.company) == "null" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                        Companies: <b>{this.state.companies_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeCompany(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.companies).map((key, i) => {
                                            if (
                                                (this.state.companies[key].product_owner_id == 1 || this.state.companies[key].product_owner_id == 7 || this.state.companies[key].product_owner_id == 15) &&
                                                (localStorage.getItem('companies') == "ALL" || localStorage.getItem('companies').indexOf(',' + key + ',') != -1)) {
                                                    return (<DropdownItem key={key} onClick={() => this.changeCompany(key)}>{this.state.companies[key].name}</DropdownItem>)
                                                }
                                            }
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color={(String(this.state.product_parent_id) == "null" ? "primary" : "warning")}
                                                    style={{fontSize: '13px', width: 'auto'}}>
                                        Product: {this.state.product_parent_name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeProduct(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {this.state.products_sort.map((product) =>
                                            <DropdownItem key={product.id} onClick={() => this.changeProduct(product.id)}>{product.name}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color={(String(this.state.factor_id) == "null" ? "primary" : "warning")}
                                                    style={{fontSize: '13px', width: 'auto'}}>
                                        Factor: {this.state.factor_name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeFactor(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.factors).map(function (factor_id) {
                                            return (
                                                <DropdownItem key={factor_id}
                                                              onClick={() => this.changeFactor(factor_id)}>{this.state.factors[factor_id].name}</DropdownItem>
                                            )
                                        }, this)}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color={(String(this.state.category_id) == "null" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                        Category: {this.state.category_name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeCategory(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.categories).map((key, i) =>
                                            <DropdownItem key={key}
                                                          onClick={() => this.changeCategory(this.state.categories[key].product_category_id)}>{this.state.categories[key].name}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className="btn btn-secondary btn-xs float-right mr-1" onClick={this.export}>
                                <i className="fas fa-download"></i> Export
                            </div>
                            <div className="card-title">{this.state.accounts.length} Matching Accounts</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.accounts.length > 0 ? "table table-hover table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="text-left" onClick={e => this.onSort(e, 'license_num')}><i
                                            className={(this.state.sort != "license_num" ? "fas fa-sort" : (this.state.sort_order != 1 ? "fas fa-caret-down" : "fas fa-caret-down fa-rotate-180"))}></i> License
                                            #
                                        </th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i
                                            className={(this.state.sort != "name" ? "fas fa-sort" : (this.state.sort_order != 1 ? "fas fa-caret-down" : "fas fa-caret-down fa-rotate-180"))}></i> Name
                                        </th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'city')}><i
                                            className={(this.state.sort != "city" ? "fas fa-sort" : (this.state.sort_order != 1 ? "fas fa-caret-down" : "fas fa-caret-down fa-rotate-180"))}></i> City
                                        </th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'since_last_order')}><i
                                            className={(this.state.sort != "since_last_order" ? "fas fa-sort" : (this.state.sort_order != 1 ? "fas fa-caret-down" : "fas fa-caret-down fa-rotate-180"))}></i> Since
                                            Last Order
                                        </th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'num_orders')}><i
                                            className={(this.state.sort != "num_orders" ? "fas fa-sort" : (this.state.sort_order != 1 ? "fas fa-caret-down" : "fas fa-caret-down fa-rotate-180"))}></i> #
                                            Orders
                                        </th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'total_spent')}><i
                                            className={(this.state.sort != "total_spent" ? "fas fa-sort" : (this.state.sort_order != 1 ? "fas fa-caret-down" : "fas fa-caret-down fa-rotate-180"))}></i> Total
                                            Sales
                                        </th>
                                        <th className={(String(this.state.company) == "null" && String(this.state.product_parent_id) == "null" && String(this.state.category_id) == "null" ? "text-right" : "d-none")}
                                            onClick={e => this.onSort(e, 'owing')}><i
                                            className={(this.state.sort != "owing" ? "fas fa-sort" : (this.state.sort_order != 1 ? "fas fa-caret-down" : "fas fa-caret-down fa-rotate-180"))}></i> Outstanding
                                            Bal.
                                        </th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'hl')}><i
                                            className={(this.state.sort != "hl" ? "fas fa-sort" : (this.state.sort_order != 1 ? "fas fa-caret-down" : "fas fa-caret-down fa-rotate-180"))}></i> Case
                                            Eq. (355ml)
                                        </th>
                                        <th className="text-right" onClick={e => this.onSort(e, 'hl')}><i
                                            className={(this.state.sort != "hl" ? "fas fa-sort" : (this.state.sort_order != 1 ? "fas fa-caret-down" : "fas fa-caret-down fa-rotate-180"))}></i> Case
                                            Eq.
                                        </th>
                                        <th className="text-right">Delivery Day</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.entries(this.state.accounts).map(([key, account]) => {
                                        num_orders = num_orders + account.num_orders;
                                        total_spent = total_spent + account.total_spent;
                                        owing = owing + account.owing;
                                        hl = hl + account.hl;
                                        return (
                                            <tr key={key} onClick={this.goToAccount.bind(this, account.account_id)}>
                                                <td className="text-left">{account.license_num}</td>
                                                <td className="text-left">{account.name} <small className="text-muted">{account.address1}</small></td>
                                                <td className="text-left">{account.city}</td>
                                                <td className='text-right'>{account.since_last_order}</td>
                                                <td className="text-right">{account.num_orders}</td>
                                                <td className="text-right">${account.total_spent.toLocaleString("en-US")}</td>
                                                <td className={(String(this.state.company) == "null" && String(this.state.product_parent_id) == "null" && String(this.state.category_id) == "null" ? "text-right" : "d-none")}>${account.owing.toLocaleString("en-US")}</td>
                                                <td className="text-right">{(account.hl * 11.7).toFixed(1)}</td>
                                                <td className="text-right">{(account.hl).toFixed(1)}</td>
                                                <td className="text-right">{getDeliveryDate(account.d_day, account.d_week, "display")}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th className="text-right" colSpan={4}>Total:</th>
                                        <th className="text-right">{num_orders.toLocaleString("en-US")}</th>
                                        <th className="text-right">${total_spent.toLocaleString("en-US")}</th>
                                        <th className={(String(this.state.company) == "null" && String(this.state.product_parent_id) == "null" && String(this.state.category_id) == "null" ? "text-right" : "d-none")}>${owing.toLocaleString("en-US")}</th>
                                        <th className="text-right">{(hl * 11.7).toFixed(1)}</th>
                                        <th className="text-right">{(hl).toFixed(1)}</th>
                                        <th></th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (AccountList);